import React from 'react';
import styled from 'styled-components';
import {
  Table,
  TableBody,
  TableCell as TableCellMui,
  TableContainer,
  TableHead,
  TableRow as TableRowMui,
  Link as LinkMui,
  Typography as TypographyMui,
  Paper as PaperMUI
} from '@material-ui/core/';

const Paper = styled(PaperMUI)`
  min-height: 500px;
  padding-bottom: 0px;
  min-height: calc(90vh - 105px - 60px);
  /* min-height: calc(70vh); */
`;

const TableCell = styled(TableCellMui)`
  border-bottom: none;
  padding-left: ${props => props.paddingleft};
  vertical-align: top;
`;

const FirstRow = styled(TableRowMui)`
  background-color: #e1e1e1;
  font-weight: bold;
  height: 20px;
  padding: 0;
  margin: 0;
`;

const FirstRowTableCell = styled(TableCell)`
  padding-top: 7px;
  padding-bottom: 5px;
`;

const TableRow = styled(TableRowMui)`
  font-weight: 600 !important;
  border-top: ${props => props.bodertop};
  background-color: ${props => props.backgroundcolor};
`;

const Typography = styled(TypographyMui)`
  color: #000000;
  min-width: ${props => props.minwidth};
  font-weight: ${props => props.bold && '600'};

  padding-top: ${props => props.paddingtop};
  ${props => props.theme.breakpoints.down('xs')} {
    min-width: 0;
  }
`;

const Link = styled(LinkMui)`
  color: #2f78c7;
`;

const CellContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const DownloadsContainer = styled.div`
  min-height: 60px;
  /* margin-bottom: 3px; */
  /* border-top: 1px solid green; */
`;

const OpenSourceTable = ({ rows }) => {
  let bodertop = `2px solid rgba(0,0,0,.01)`;
  let isGrayBackground = true;

  const TableRowsList = rows.map((row, index) => {
    if (row.component) isGrayBackground = !isGrayBackground;
    const backgroundColor = isGrayBackground && '#f8f8f8';

    return (
      <TableRow
        key={row.licensesUrl}
        bodertop={(index > 0 && !row.component && bodertop).toString()}
        backgroundcolor={backgroundColor.toString()}
      >
        <TableCell align="left" paddingleft="30px">
          <Typography variant="subtitle1">{row.component}</Typography>
        </TableCell>
        <TableCell align="right" paddingright="15px">
          <Typography variant="subtitle1">{row.version}</Typography>
        </TableCell>
        <TableCell align="left">
          <CellContentContainer>
            <DownloadsContainer>
              {row.licensesUrl && (
                <Typography minwidth="215px" variant="subtitle1">
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    download
                    href={`${process.env.PUBLIC_URL}/${row.licensesUrl}`}
                  >
                    OSS usage
                  </Link>
                </Typography>
              )}
              {row.codeUrl && (
                <Typography
                  minwidth="215px"
                  variant="subtitle1"
                  paddingtop="16px"
                >
                  <Link target="_blank" rel="noreferrer" href={row.codeUrl}>
                    Source Code
                  </Link>
                </Typography>
              )}
            </DownloadsContainer>
          </CellContentContainer>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Paper>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <FirstRow>
              <FirstRowTableCell align="left" paddingleft="30px">
                <Typography bold="true" variant="overline">
                  Component
                </Typography>
              </FirstRowTableCell>
              <FirstRowTableCell align="right" paddingright="25px">
                <Typography bold="true" variant="overline">
                  Version
                </Typography>
              </FirstRowTableCell>
              <FirstRowTableCell align="left">
                <CellContentContainer>
                  <Typography
                    bold="true"
                    minwidth="215px"
                    altminwidth="150px"
                    variant="overline"
                  >
                    Download
                  </Typography>
                </CellContentContainer>
              </FirstRowTableCell>
            </FirstRow>
          </TableHead>
          <TableBody>{TableRowsList}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default OpenSourceTable;
