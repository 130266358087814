import React from 'react';
import styled from 'styled-components';
import {
  Typography as TypographyMui,
  Paper as PaperMUI
} from '@material-ui/core/';

const Typography = styled(TypographyMui)``;

const Paper = styled(PaperMUI)`
  padding-top: ${props => props.theme.spacing(3) + 'px'};
  padding-bottom: ${props => props.theme.spacing(5) + 'px'};
  padding-left: ${props => props.theme.spacing(3) + 'px'};
  padding-right: ${props => props.theme.spacing(3) + 'px'};
  margin-bottom: 50px;
`;

const OpenSourcePageDescription = () => {
  return (
    <Paper elevation={3}>
      <Typography paragraph variant="h5">
        Open Source Software Usage
      </Typography>
      <Typography>
        Precise Biometrics and Precise YOUNiQ believe that open source is good
        for everyone.
      </Typography>

      <Typography paragraph>
        Moreover, we greatly appreciate the efforts that go into open source
        projects, and this page recognizes those efforts.
      </Typography>

      <Typography paragraph>
        Listed below are the current open source components included in the
        Precise YOUNiQ solution.
      </Typography>
      <Typography>
        Should you have open source related questions, please contact us at
        opensource@precisebiometrics.com.
      </Typography>
    </Paper>
  );
};

export default OpenSourcePageDescription;
