import React from 'react';
import { Container, Typography, Box } from '@material-ui/core/';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <Container>
          <Box
            display="flex"
            justifyContent="center"
            paddingTop="15%"
            flexDirection="column"
          >
            <Typography paragraph align="center" variant="h5">
              Could not display Open Source Declarations
            </Typography>
            <Box paddingTop="15px">
              <Typography align="center" color="textSecondary">
                {/* {this.state.error && this.state.error.message.toString()} */}
              </Typography>
            </Box>
          </Box>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
