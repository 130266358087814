export const request = async (endpoint, protocol, body, blobResponse) => {
  const result = await fetch(`${endpoint}`, {
    method: protocol,
    credentials: 'include',
    headers: {
      'Access-Control-Allow-Credentials': true,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
  // result.sessionGotExpired = handleSession(endpoint, result.status);

  result.requestBody = body;
  try {
    if (blobResponse) result.responseBody = await result.blob();
    else result.responseBody = await result.text();
  } catch {}

  return result;
};

export const flattenArray = arr => {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flattenArray(toFlatten) : toFlatten
    );
  }, []);
};
