import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AppBar from './components/AppBar.jsx';
import MainPageContainer from './components/MainPageContainer';
import OpenSourcePage from './views/OpenSourcePage/OpenSourcePage';
import ErrorBoundary from './views/ErrorBoundary/ErrorBoundary';

function App() {
  return (
    <>
      <AppBar />
      <ErrorBoundary>
        <MainPageContainer>
          <Switch>
            <Route path="/" exact component={OpenSourcePage} />
            <Redirect to="/" />
          </Switch>
        </MainPageContainer>
      </ErrorBoundary>
    </>
  );
}

export default App;
