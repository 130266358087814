import React, { useEffect, useState } from 'react';

import { flattenArray } from '../../utils';

import OpenSourceTable from '../../components/OpenSourcePage/OpenSourceTable';
import OpenSourcePageDescription from '../../components/OpenSourcePage/OpenSourcePageDescription';

const OpenSourcePage = () => {
  const [rows, setRows] = useState([]);
  const [downloadsList, setDownloadsList] = useState(undefined);

  const createRow = (component, version, licensesUrl, codeUrl) => {
    return { component, version, licensesUrl, codeUrl };
  };

  useEffect(() => {
    fetch('components/downloads.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('No OSD list found.');
        }
        return response.json();
      })
      .then(data => setDownloadsList(data));
  }, []);

  useEffect(() => {
    if (downloadsList) {
      const downloads = downloadsList.components;

      const rows = downloads.map(component => {
        const versions = component.versions.map((version, index) => {
          const licensesUrl = version.licensesUrl
            ? `${version.licensesUrl}`
            : '';
          const codeUrl = version.codeUrl ? `${version.codeUrl}` : '';

          if (index === 0) {
            return createRow(
              component.name,
              version.version,
              licensesUrl,
              codeUrl
            );
          } else {
            return createRow('', version.version, licensesUrl, codeUrl);
          }
        });

        return versions;
      });

      if (!rows.length) throw new Error('No OSD list found.');

      const flattenedRows = flattenArray(rows);
      setRows(flattenedRows);
    }
  }, [downloadsList]);

  return (
    <>
      <OpenSourcePageDescription />
      <OpenSourceTable rows={rows} />
    </>
  );
};

export default OpenSourcePage;
