import React from 'react';
import styled from 'styled-components';
import { Container } from '@material-ui/core';

const PageContainer = styled(Container)`
  /* min-height: calc(90vh - 105px - 60px); */

  margin-top: 50px;
  margin-bottom: 50px;
`;

const MainPageContainer = ({ children }) => {
  return <PageContainer maxWidth="md">{children}</PageContainer>;
};

export default MainPageContainer;
