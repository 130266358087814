import React from 'react';
import CssBaseLine from '@material-ui/core/CssBaseline';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

import defaultTheme from './styling/themes';

const StylingConfigWrapper = ({ children }) => (
  <>
    <CssBaseLine />
    <ThemeProvider theme={defaultTheme}>
      {/* <GlobalStyle /> */}
      <SCThemeProvider theme={defaultTheme}>
        <StylesProvider injectFirst>{children}</StylesProvider>
      </SCThemeProvider>
    </ThemeProvider>
  </>
);
export default StylingConfigWrapper;
