import React from 'react';
import styled from 'styled-components';
import {
  AppBar as AppBarMui,
  Typography,
  Toolbar,
  Link as LinkMUI
} from '@material-ui/core';

import youniqLogo from '../assets/Youniq_logo.png';

const Link = styled(LinkMUI)`
  display: flex;
  justify-content: center;
  width: 131px;
  align-items: flex-start;
`;

const YouniqLogo = styled.img.attrs({
  src: youniqLogo
})`
  width: 100%;
  height: auto;
`;

const StyledAppBar = styled(AppBarMui)`
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
`;

const AppBarTitleContainer = styled.div`
  margin-left: 20px;
`;

const AppBar = () => {
  return (
    <StyledAppBar position="static">
      <Toolbar>
        <Link
          href="https://precisebiometrics.com/products/youniq/"
          target="_blank"
          rel="noreferrer"
        >
          <YouniqLogo />
        </Link>
        <AppBarTitleContainer>
          <Typography variant="subtitle1">
            Open Source Software Usage
          </Typography>
        </AppBarTitleContainer>
      </Toolbar>
    </StyledAppBar>
  );
};

export default AppBar;
